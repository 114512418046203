import { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

import { cn } from "~/utils/classnames";

import "./Accordion.css";
import type { AccordionProps } from "./Accordion.types";

/**
 * Composant qui permet d'afficher du contenu lorsqu'on le déplie en cliquant dessus.
 */
export const Accordion = ({
  title,
  defaultOpen = false,
  children,
  className,
  ...props
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={cn("Accordion", isOpen && "Accordion--open", className)} {...props}>
      <button className="Accordion-header" onClick={toggleOpen}>
        <span className="Accordion-title">{title}</span>

        <RiArrowDownSLine className="Accordion-icon svgIcon" />
      </button>
      <div className="Accordion-hiddenChildren">{children}</div>
    </div>
  );
};
